import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import __Img_0 from "../../Assets/Image/Machine_img.png";
import __Img_1 from "../../Assets/Image/Machine_img1.png";
import __Img_2 from "../../Assets/Image/Machine_img2.png";
import __Img_3 from "../../Assets/Image/Machine_img3.png";
import __Img_4 from "../../Assets/Image/Machine_img4.png";
import Fade from 'react-reveal/Fade';

// Image paths


const MachineLearning = () => {

    const images = {
        "0": __Img_0,
        "1": __Img_1,
        "2": __Img_2,
        "3": __Img_3,
        "4": __Img_4,
    };

    const [activeKey, setActiveKey] = useState("0");

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    return (
        <section className='__ai-section' id="product">
            <div className='__custom_container'>
                <Row>
                    <Col sm={12}>
                        <div className='__aiHeader'>
                            <h4>Machine Learning & AI</h4>
                            <p>AI (ML) is the investigation of PC calculations that can work on naturally through
                                experience and by the utilization of information. It is viewed as a piece of manmade brainpower. AI calculations fabricate a model dependent on example
                                information, known as "preparing information", to settle on forecasts or choices
                                without being unequivocally modified to do as such.</p>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <div className='__aiLeft'>
                            <h5>Broadly, there are 3 types of Machine Learning Algorithms</h5>

                            <Accordion activeKey={activeKey} onSelect={handleSelect}>
                                <Fade left>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>1. Supervised Learning</Accordion.Header>
                                        <Accordion.Body>
                                            <p>How it functions: This calculation comprises of an objective/result variable (or
                                                ward variable) which is to be anticipated from a given arrangement of
                                                indicators (autonomous factors). Utilizing these arrangements of factors, we
                                                create a capacity that guides contributions to wanted yields. The preparation cycle proceeds until the model accomplishes an ideal degree of precision on
                                                the preparation information. Instances of Directed Learning: Relapse, Choice
                                                Tree, Irregular Woodland, KNN, Calculated Relapse, and so on</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                                <Fade left>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>2. Unsupervised Learning</Accordion.Header>
                                        <Accordion.Body>
                                            <p>How it functions: In this calculation, we don't have any objective or result
                                                variable to anticipate/gauge. It is utilized for bunching populaces in various
                                                gatherings, which is broadly utilized for dividing clients in various gatherings for
                                                explicit intercession. Instances of Solo Learning: Apriori calculation, K-implies.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                                <Fade left>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>3. Reinforcement Learning</Accordion.Header>
                                        <Accordion.Body>
                                            <p>How it works: Utilizing this calculation, the machine is prepared to settle on
                                                explicit choices. It works thusly: the machine is presented to a climate where it
                                                trains itself ceaselessly utilizing experimentation. This machine gains from past
                                                experience and attempts to catch the most ideal information to settle on exact
                                                business choices. Illustration of Support Learning: Markov Choice Cycle</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Fade>

                                <Fade left>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header> <KeyboardArrowRightRounded /> 1 Soft Cap for Consumer Loyalty</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Delicate Cap of client dedication will be produced by utilizing AI models which
                                                will begin from a base measure of $10.0 and will begin to ascend as clients
                                                accomplish an ever-increasing number of exchanges with an ever-increasing
                                                amount of information. We will actually want to make the exchange expenses
                                                most reduced utilizing ML calculations.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                                <Fade left>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header><KeyboardArrowRightRounded /> 2 Hard cap for Consumer Loyalty:</Accordion.Header>
                                        <Accordion.Body>
                                            <p>The Hard-Cap of client faithfulness will be fixed, which is $70. Clients will not have the option to utilize more than $70 without hazard the executives.</p>
                                            <p> To ensure that the protocol works and is monetized, there are fees involved. The fee
                                                setup will vary depending on whether AI Alpha Trade is in on the APTD Blockchain or on any
                                                open smart contract blockchain like Binance smart chain among others. Fees will
                                                be updated regularly and are required to pass voting in the DAO (Decentralized
                                                Autonomous Organization).</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Fade>
                            </Accordion>
                        </div>
                    </Col>

                    <Col lg={6}>
                        <Fade right>
                            <div className='__aiRight'>
                                {activeKey ?
                                    <img src={images[activeKey]} alt="Machine-ai" />
                                    :
                                    <img src={__Img_0} alt="Machine-ai" />
                                }
                            </div>
                        </Fade>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default MachineLearning;
